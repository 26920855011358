export enum APIs {
  Auth = 'Auth',
  APIv3 = 'APIv3',
  Realtime = 'Realtime',
  Campaigns = 'Campaigns',
  Integrations = 'Integrations',
  Tracking = 'Tracking',
  CallsManagement = 'CallManagement',
  Messages = 'messages',
  Calling = 'Calling',
  Contacts = 'Contacts',
}
