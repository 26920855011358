import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WindowSizeStateEnum } from '../enums/window-size-state.enum';
import { ElectronService } from './electron/electron.service';
import { IpcService } from './ipc.service';

@Injectable()
export class WindowService implements OnDestroy {
  currentWindowSizeState: WindowSizeStateEnum = WindowSizeStateEnum.COLLAPSED;
  currentWindowDimensions: { width: number; height: number };
  currentWindowSizeStateObserver$: Observable<WindowSizeStateEnum>;

  private currentWindowSizeStateSource$: BehaviorSubject<WindowSizeStateEnum> =
    new BehaviorSubject<WindowSizeStateEnum>(WindowSizeStateEnum.COLLAPSED);

  private unsubscribe$ = new Subject<void>();

  constructor(
    private electronService: ElectronService,
    private ipcService: IpcService,
  ) {
    this.currentWindowSizeStateSource$.next(this.currentWindowSizeState);
    this.currentWindowSizeStateObserver$ =
      this.currentWindowSizeStateSource$.asObservable();
    this.currentWindowSizeStateObserver$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.resizeWindow());
  }

  get currentWindowSizeStateVal(): WindowSizeStateEnum {
    return this.currentWindowSizeState;
  }

  get isExpanded(): boolean {
    return this.currentWindowSizeState === WindowSizeStateEnum.EXPANDED;
  }

  get isCollapsed(): boolean {
    return this.currentWindowSizeState === WindowSizeStateEnum.COLLAPSED;
  }

  get expandedDimensions(): { width: number; height: number } {
    return { width: 1384, height: 760 };
  }

  get collapsedDimensions(): { width: number; height: number } {
    return { width: 425, height: 760 };
  }

  maximized(url: string): void {
    const params = [
      'directories=yes',
      'titlebar=yes',
      'toolbar=yes',
      'location=yes',
      'status=yes',
      'menubar=yes',
      'resizable=yes',
      'scrollbars=yes',
      'height=' + screen.height,
      'width=' + screen.width,
      'fullscreen=yes',
    ].join(',');

    const popup = window.open(url, '_blank', params);
    if (!this.electronService.isElectronApp && popup) {
      popup.moveTo(0, 0);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateSizeState(): WindowSizeStateEnum {
    return this.currentWindowSizeStateSource$.getValue() ===
      WindowSizeStateEnum.COLLAPSED
      ? WindowSizeStateEnum.EXPANDED
      : WindowSizeStateEnum.COLLAPSED;
  }

  changeWindowSizeState(): void {
    this.currentWindowSizeState = this.updateSizeState();
    this.currentWindowDimensions =
      this.currentWindowSizeState === WindowSizeStateEnum.COLLAPSED
        ? this.collapsedDimensions
        : this.expandedDimensions;
    this.currentWindowSizeStateSource$.next(this.currentWindowSizeState);
  }

  private resizeWindow(): void {
    this.ipcService.send('resize-window', this.currentWindowDimensions);
  }
}
