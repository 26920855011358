import { StorageEnum } from '../../_core/enums/storage.enum';
import { LoggerUtil } from './logger.util';

export class StorageUtils {
  static storageAvailable(type: StorageEnum): boolean {
    try {
      const storage = window[type],
        storageKeyValue = '__storage_test__';
      storage.setItem(storageKeyValue, storageKeyValue);
      storage.removeItem(storageKeyValue);
      return true;
    } catch (e) {
      LoggerUtil.error(
        '[StorageUtils]: Error checking storage availability',
        {},
        e,
      );
      return false;
    }
  }
}
