import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';
import { SupportedSsoProvider } from 'src/app/pages/login/login.types';

import { environment } from '../../../../environments/environment';
import {
  SnackBarTypes,
  SnackBarWrapperService,
} from '../../../_shared/components/snack-bar-wrapper/snack-bar-wrapper.service';
import {
  finalizeSSOUrl,
  hasOwnProperty,
  parseJwt,
} from '../../../_shared/helpers/helper-functions';
import { LoggerUtil } from '../../../_shared/utils/logger.util';
import { CookieService } from '../../cookie.service';
import { LoadStateEnum } from '../../enums/load-state.enum';
import { StorageEnum } from '../../enums/storage.enum';
import { USER_UNSUPPORTED_GROUPS } from '../../enums/user/user-unsupported-group.enum';
import { CTResponse } from '../../interfaces/ctresponse.interface';
import { LoginErrorResponse } from '../../interfaces/login-error-response.interface';
import { LoginResponse } from '../../interfaces/login-response.interface';
import { GetAuthInitModel } from '../../models/get-auth-init.model';
import { InitAuthModel } from '../../models/init-auth.model';
import { LoginSsoModel } from '../../models/login-sso.model';
import { RefreshTokenModel } from '../../models/refresh-token.model';
import { User } from '../../models/user';
import { AuthLoginSsoType } from '../../types/auth-login-sso.type';
import { VoiceSettingService } from '../audio/voice-setting.service';
import { EnvironmentService } from '../environment.service';
import { LocalStorageService } from '../local-storage.service';
import { UserSettingsService } from '../user/user-settings.service';
import { UserService } from '../user/user.service';
import { AuthenticationRepository } from './authentication.repository';

export const LOGIN_ERROR_MESSAGES: { [key: string]: string } = {
  'user-not-found': $localize`E-mail or password is incorrect`,
  'Invalid credentials provided.': $localize`E-mail or password is incorrect`, // TODO: CT-auth should return some code
  'not-allowed-country': $localize`Not allowed to login from this country. Contact your administrator`,
  'google-sso-enforced': $localize`Your account requires loggin in via Google SSO`,
  'google-sso-not-enabled': $localize`You account does not support logging in via Google SSO`,
};

@Injectable()
export class AuthenticationService {
  unauthorized$: Subject<boolean> = new Subject<boolean>();
  #onLogout$: Subject<void> = new Subject<void>();
  onLogout$: Observable<void> = this.#onLogout$.asObservable();

  returnUrl: string;
  loading: boolean;

  processingMessage = false;

  private _accessToken: string = null;
  private _idToken: string = null;

  private readonly ACCESS_TOKEN_KEY = 'ct-at';
  private readonly ID_TOKEN_KEY = 'ct-idt';

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
    private environmentService: EnvironmentService,
    private authenticationRepository: AuthenticationRepository,
    private router: Router,
    private snackBarWrapperService: SnackBarWrapperService,
    private ngZone: NgZone,
    private localStorageService: LocalStorageService,
    private userSettingsService: UserSettingsService,
    private voiceSettingService: VoiceSettingService,
  ) {}

  get accessToken(): string {
    if (!this._accessToken) {
      return this.loadTokenFromCookie(this.ACCESS_TOKEN_KEY);
    }
    return this._accessToken;
  }

  set accessToken(accessToken: string) {
    if (accessToken) {
      const accessTokenData = parseJwt(accessToken);
      this.storeTokenInCookie(
        this.ACCESS_TOKEN_KEY,
        accessToken,
        accessTokenData.exp,
      );
    }
    this._accessToken = accessToken;
  }

  get idToken(): string {
    if (!this._idToken) {
      this._idToken = this.loadTokenFromCookie(this.ID_TOKEN_KEY);
    }
    return this._idToken;
  }

  set idToken(idToken: string) {
    if (idToken) {
      const userData = this.setUser(idToken);
      this.storeTokenInCookie(this.ID_TOKEN_KEY, idToken, userData.exp);
    }
    this._idToken = idToken;
  }

  async login(email: string, password: string): Promise<void> {
    try {
      this.loading = true;
      this.clearUserCookies();
      const { data }: CTResponse<LoginResponse> = await firstValueFrom(
        this.authenticationRepository.login({ email, password }),
      );
      const { accessToken } = data;

      this.loading = false;

      if (!accessToken) {
        this.notRecognizedError();
        return;
      }

      this.accessToken = accessToken;

      const user = this.setUser(accessToken);

      this.handleLoggedUser(user);

      this.storeRefreshToken(data.refreshToken);

      this.userSettingsService.reset();
    } catch (err) {
      this.handleError(err);
    }
  }
  /**
   * Sends logout request which clears all HTTPOnly cookies includes refresh token
   */
  logout(trigger: string, reload: boolean, navigateToLogin = true): void {
    this.#onLogout$.next();
    LoggerUtil.warn(`[AuthenticationService]: logout from ${trigger}`, {
      reload,
      navigateToLogin,
    });
    firstValueFrom(this.authenticationRepository.logout())
      .then(() => {
        this.removeRefreshToken();
        if (navigateToLogin) {
          this.navigateToLogin(false, reload, trigger);
        }
      })
      .catch(err => {
        this.handleError(err.error);
      });
  }

  logoutOnUnauthorized(): void {
    this.removeRefreshToken();
  }

  /**
   * Navigates to login page
   * @param unauthorized - boolean value which indicates if user is unauthorized
   * @param reload - boolean value which indicates if page should be reloaded after navigation to login page
   */
  navigateToLogin(
    unauthorized: boolean = false,
    reload: boolean = true,
    trigger: string,
  ): void {
    LoggerUtil.warn(
      `[AuthenticationService]: navigateLogin from ${trigger}`,
      {},
    );
    if (unauthorized) {
      this.unauthorized$.next(true);
    }

    this.router
      .navigate(['/login'])
      .then(() => {
        this.clearUserSession();
        if (reload) {
          window.location.reload();
        }
      })
      .catch(e => {
        LoggerUtil.error(
          `[AuthenticationService]: Navigate to login failed from ${trigger}`,
          {},
          e,
        );
      });
  }

  authRefreshToken(): Observable<CTResponse<RefreshTokenModel>> {
    const storedRefreshToken = this.getRefreshToken();

    LoggerUtil.info(
      '[AuthenticationService]: authRefreshToken',
      {
        filled: !!storedRefreshToken,
      },
      {},
    );
    return this.authenticationRepository.refreshToken(storedRefreshToken).pipe(
      tap(({ data }: CTResponse<LoginSsoModel>) => {
        const { accessToken, idToken, refreshToken } = data;
        if (idToken) {
          this.idToken = idToken;
        } else {
          this.setUser(accessToken);
        }
        if (refreshToken) {
          this.storeRefreshToken(refreshToken);
        }
        this.accessToken = accessToken;
      }),
    );
  }

  getAuthLoginSso(
    authLoginSsoType: AuthLoginSsoType,
  ): Observable<CTResponse<LoginSsoModel>> {
    this.clearUserCookies();
    return this.authenticationRepository
      .ssoLogin(authLoginSsoType)
      .pipe(
        catchError((httpErrorResponse: HttpErrorResponse) =>
          this.handleError(httpErrorResponse.error),
        ),
      );
  }

  openIncognitoUrl(provider: SupportedSsoProvider): void {
    try {
      const url = finalizeSSOUrl(provider, this.environmentService.origin);
      // 'authPopupCloudtalk' value is important to open the new window instead of new tab
      window.open(url, 'authPopupCloudtalk', 'popup,width=520,height=600');
      window.addEventListener('message', this.processMessage.bind(this), false);
    } catch (e) {
      LoggerUtil.error(
        '[AuthenticationService]: Opening incognito failed',
        {},
        e,
      );
    }
  }

  getAuthInit(email: string): Observable<GetAuthInitModel> {
    return this.authenticationRepository.ssoAuthInit(email).pipe(
      map((response: CTResponse<InitAuthModel>) => {
        return { response, loadingStatus: LoadStateEnum.LOADED };
      }),
      startWith<GetAuthInitModel>({ loadingStatus: LoadStateEnum.LOADING }),
      catchError((error: HttpErrorResponse) =>
        of({
          response: { success: false, message: error.error.error.message },
          loadingStatus: LoadStateEnum.ERROR,
        }),
      ),
    );
  }

  private storeTokenInCookie(
    tokenKey: string,
    tokenValue: string,
    expireTimestamp: number,
  ) {
    this.cookieService.setCookie({
      name: tokenKey,
      value: tokenValue,
      expireTimestamp: expireTimestamp,
      path: '/',
      partitioned: true,
    });
  }

  private loadTokenFromCookie(tokenKey: string): string {
    const loadedToken = this.cookieService.getCookie(tokenKey);
    if (!loadedToken) {
      return null;
    }
    return loadedToken;
  }

  private clearUserSession(): void {
    this._accessToken = null;
    this._idToken = null;
    this.processingMessage = false;

    this.clearUserCookies();
  }

  setUser(token: string): User {
    try {
      const user = this.userService.setUser(token);
      this.checkUserRole(user);
      return user;
    } catch (e) {
      LoggerUtil.error('[AuthenticationService]: Set user failed', {}, e);
    }
    return null;
  }

  private checkUserRole(user: User): void {
    if (this.isUserGroupUnsupported(user)) {
      this.logout('AuthenticationService:setUser', false, true);
      throw new Error('User has bad role');
    }
  }

  private handleLoggedUser(loggedUser: User): void {
    let showOnboarding = true;
    if (loggedUser) {
      showOnboarding = !(
        this.localStorageService.getItem('show-onboarding') === 'false' ||
        (loggedUser.id < 8150 &&
          ![100201, 100552].includes(loggedUser.company_id))
      );
    }

    if (showOnboarding && !this.environmentService.isBeta()) {
      this.router.navigate(['first-steps']).catch(() => {});
    } else {
      this.router.navigate([this.returnUrl || 'p/dialpad']).catch(() => {});
      this.unauthorized$.next(false);
    }
  }

  private handleError(err: LoginErrorResponse): Observable<null> {
    this.ngZone.run(() => {
      const parsedErrorMessage =
        (err?.error && err?.error?.error?.message) || null;

      if (parsedErrorMessage) {
        if (hasOwnProperty(LOGIN_ERROR_MESSAGES, parsedErrorMessage)) {
          this.invalidSnackBar(LOGIN_ERROR_MESSAGES[parsedErrorMessage], false);
        } else {
          this.notRecognizedError(err);
        }
      } else {
        this.notRecognizedError(err);
      }
    });

    return of(null);
  }

  private notRecognizedError(err?: unknown): void {
    if (err) {
      LoggerUtil.error(
        '[AuthenticationService]: Not recognized error',
        {},
        err,
      );
    }

    this.invalidSnackBar(
      $localize`Unable to login right now. Please try again`,
    );
  }

  private invalidSnackBar(text: string, exception: boolean = true): void {
    this.loading = false;
    this.snackBarWrapperService.openSnackBar({
      message: text,
      snackType: SnackBarTypes.ERROR,
      exception,
      duration: 3000,
    });
  }

  private async processMessage(
    event: MessageEvent,
    ssoUrl: string,
  ): Promise<void> {
    if (typeof event.data === 'string') {
      try {
        const { code } = JSON.parse(event.data);

        if (!code && this.processingMessage) {
          return;
        }

        this.processingMessage = true;

        const { data } = await firstValueFrom(
          this.getAuthLoginSso({
            authorizationCode: code,
            redirectUri: [
              this.environmentService.origin,
              environment.ssoAuthConfig.oauth.redirectSignIn,
            ].join(''),
          }),
        );

        const { accessToken, refreshToken } = data;

        this.accessToken = accessToken;

        const user = this.setUser(accessToken);
        this.handleLoggedUser(user);

        this.storeRefreshToken(refreshToken);
        this.userSettingsService.reset();
      } catch (e) {
        LoggerUtil.info(
          'AuthenticationService:processMessage',
          {
            event,
            ssoUrl,
          },
          e,
        );
        this.ngZone.run(() => {
          this.router
            .navigate(['/', 'login', 'sso'], {
              state: {
                ssoError: $localize`You was not authorized. Please try again later.`,
              },
            })
            .catch(() => {});
        });
      } finally {
        this.processingMessage = false;
      }
    }
  }

  get logoutLocation(): string {
    return [
      'https://',
      environment.ssoAuthConfig.oauth.domain,
      '/logout?client_id=',
      environment.ssoAuthConfig.userPoolWebClientId,
      '&logout_uri=',
      this.environmentService.origin,
    ].join('');
  }

  private clearUserCookies(): void {
    this.cookieService.getAllCookieNames().map((cookie: string) => {
      if (cookie !== this.voiceSettingService.cookieName) {
        this.cookieService.deleteCookie(cookie, '.cloudtalk.io', '/');
        this.cookieService.deleteCookie(cookie, null, '/');
      }
    });
    this.userService.removeUser();
  }

  private isUserGroupUnsupported(user: User) {
    return user?.group && USER_UNSUPPORTED_GROUPS.includes(Number(user.group));
  }

  private storeRefreshToken(refreshToken: string): void {
    LoggerUtil.info('[AuthenticationService]: Store refresh token', {}, {});
    this.localStorageService.setItem(
      StorageEnum.REFRESH_TOKEN,
      refreshToken,
      false,
    );
  }

  private getRefreshToken(): string {
    LoggerUtil.info('[AuthenticationService]: Get refresh token', {}, {});
    return this.localStorageService.getItem(StorageEnum.REFRESH_TOKEN, false);
  }

  private removeRefreshToken(): void {
    LoggerUtil.info('[AuthenticationService]: Remove refresh token', {}, {});
    this.localStorageService.removeItem(StorageEnum.REFRESH_TOKEN, false);
  }

  /**
   * @param limit how far in the future the token should expire, default 60s
   * @returns
   */
  isTokenValid(limit = 300) {
    return (
      this.accessToken &&
      parseJwt(this.accessToken)?.exp > new Date().getTime() / 1000 + limit
    );
  }
}
