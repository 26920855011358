import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LoggerUtil } from '../../_shared/utils/logger.util';
import { APIs } from '../enums/apis.enum';
import { CTResponse } from '../interfaces/ctresponse.interface';
import { RefreshTokenModel } from '../models/refresh-token.model';
import { AuthenticationService } from '../services/auth/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<RefreshTokenModel | null>(
    null,
  );

  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has('token-bypass')) {
      const modifiedReq = request.clone({
        headers: request.headers.delete('token-bypass'),
      });
      return next.handle(modifiedReq);
    }

    if (this.needsToken(request) && !this.authService.isTokenValid()) {
      return this.handleRefreshToken(request, next);
    }

    return next
      .handle(this.addToken(request))
      .pipe(catchError(error => this.handleError(error, request, next)));
  }

  private handleError(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldAttemptRefresh(error, request)) {
      return this.handleRefreshToken(request, next);
    } else {
      return throwError(() => error);
    }
  }

  private shouldAttemptRefresh(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
  ): boolean {
    return (
      error.status === 401 &&
      error.message !== 'You are not authorized to access this location' &&
      !this.isAuthRequest(request.url)
    );
  }

  private handleRefreshToken(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      LoggerUtil.warn(
        '[TokenInterceptor]: Refreshing token',
        { url: request.url },
        {},
      );

      return this.authService.authRefreshToken().pipe(
        switchMap(({ data }: CTResponse<RefreshTokenModel>) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(data);
          return next
            .handle(this.addToken(request))
            .pipe(catchError(retryError => this.handleRetryError(retryError)));
        }),
        catchError((refreshError: HttpErrorResponse) =>
          this.handleRefreshError(request, refreshError),
        ),
      );
    } else {
      // Wait for the token refresh to complete, then retry
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(() =>
          next
            .handle(this.addToken(request))
            .pipe(catchError(retryError => this.handleRetryError(retryError))),
        ),
      );
    }
  }

  private handleRefreshError(
    request: HttpRequest<unknown>,
    refreshError: HttpErrorResponse | Error,
  ): Observable<never> {
    // handle only Unauthorized errors
    LoggerUtil.warn(
      '[TokenInterceptor]: Refresh token error',
      {
        extraContent: {
          status:
            refreshError instanceof HttpErrorResponse
              ? refreshError?.status
              : 'unknown',
          error: refreshError?.message,
        },
      },
      refreshError,
    );
    if (
      !(refreshError instanceof HttpErrorResponse) ||
      refreshError.status === 401
    ) {
      this.isRefreshing = false;
      this.refreshTokenSubject.next(null); // Reset subject state in case of failure
      this.authService.logoutOnUnauthorized();
      this.authService.navigateToLogin(true, true, 'TokenInterceptor');
    }

    return throwError(() => refreshError);
  }

  private handleRetryError(retryError: HttpErrorResponse): Observable<never> {
    LoggerUtil.warn('[TokenInterceptor]: Retry request error', {}, retryError);
    return throwError(() => retryError);
  }

  private addToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (!this.needsToken(request)) {
      return request;
    }

    const headers: { [key: string]: string } = {};
    const token = this.authService.accessToken;
    const idToken = this.authService.idToken;

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    if (idToken) {
      headers['idToken'] = idToken;
    }

    return token ? request.clone({ setHeaders: headers }) : request;
  }

  private needsToken(request: HttpRequest<unknown>) {
    if (request.url?.includes('/assets/')) {
      return false;
    }

    return true;
  }

  private isAuthRequest(url: string): boolean {
    return url.includes(environment.apis[APIs.Auth]);
  }
}
